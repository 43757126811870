/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { TColumns, TRows } from 'components/CustomTable/interface';
import CustomTable from 'components/CustomTable';
import getType from 'components/TypeIcon';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ACTION_ITEM_TOOLTIP_TEXT } from 'utils/constants';
import { Box, Typography } from '@mui/material';
import { CourseManagementRequest } from 'services/interfaces';
import LoadingBox from 'components/LoadingBox';
import { useGetCourseManagementMutation } from 'services/dataApi';
import { useNavigate } from 'react-router-dom';

function getViewInstancesButton(id: string | number, _navigate: Function, count: number) {
  return (
    <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.VIEW_INSTANCES}>
      <IconButton sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => { _navigate(`instances/${id}`) }}>
        <LibraryBooksIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        <Typography color={(theme) => theme.palette.primary.main}>
          View (
          {count}
          )
        </Typography>
      </IconButton>
    </Tooltip>
  )
}

function getActionButtons(id: string | number, name: string, _navigate: Function) {
  return (
    <Box display="flex">
      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.VIEW_COURSE}>
        <IconButton sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => _navigate(`detail/${id}/${encodeURIComponent(name)}`)}>
          <VisibilityIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

function createData(items: any[], navigate: Function): TRows[] {
  return items?.map((item: any) => ({
    ...item,
    learningObjectTypeId: getType({ type: item?.learningObjectTypeId }),
    studentsCount: item?.studentsCount,
    classCount: getViewInstancesButton(item?.learningObjectId, navigate, item?.classCount),
    details: [],
    isCollapsable: false,
    courseName: '',
    actions: getActionButtons(item.learningObjectId, item?.learningObjectName, navigate),
  }));
}

const filterNonNullProperties = (obj: CourseManagementRequest) => Object.fromEntries(
  Object.entries(obj).filter(([, value]) => value !== null),
);

export default function CoursesTable({ filters }: {filters: CourseManagementRequest}) {
  const [dataSet, setDataSet] = useState<TRows[] | []>([]);

  const [getCoursesByFilter, { isLoading }] = useGetCourseManagementMutation();

  const navigate = useNavigate();

  const fetchData = async (request: CourseManagementRequest) => {
    try {
      // const response = await getCoursesByFilter(filterNonNullProperties(request) as CourseManagementRequest).unwrap();
      const response = await getCoursesByFilter(request as CourseManagementRequest).unwrap();
      if (response.length > 0) {
        setDataSet(createData(response, navigate))
      } else {
        setDataSet([]);
      }
    } catch (err) {
      setDataSet([]);
    }
  };

  useEffect(() => {
    const request: CourseManagementRequest = filters;
    fetchData(filterNonNullProperties(request) as CourseManagementRequest);
  }, [filters]);

  const columns: TColumns[] = [
    {
      name: 'Course Type',
      column: 'learningObjectTypeId',
      width: 150,
      sortable: true,
      align: 'left',
      alignTitle: 'left',
    },
    {
      name: 'Learning Object ID',
      column: 'learningObjectId',
      width: 150,
      sortable: true,
      align: 'center',
      alignTitle: 'center',
    },
    {
      name: 'Learning Object Name',
      column: 'learningObjectName',
      width: 200,
      sortable: true,
      searchable: true,
    },
    {
      name: 'Students Count',
      column: 'studentsCount',
      width: 120,
      align: 'center',
      alignTitle: 'center',
      sortable: true,
    },
    {
      name: 'Class Count',
      column: 'classCount',
      width: 120,
      align: 'left',
      alignTitle: 'left',
      sortable: true,
    },
    {
      name: 'Actions',
      column: 'actions',
      width: 120,
      sortable: false,
    },
  ];

  if (isLoading) {
    return <LoadingBox />
  }

  return (
    <CustomTable
      rows={dataSet}
      columns={columns}
      collapseAllRow={false}
      showSearchBar
    />
  )
}
