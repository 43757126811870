import React from 'react'
import FormView, { IFormItem } from 'components/FormView';
import ViewFormLayout from 'components/ViewFormLayout';
import { LearningObjectDetailsResponse } from 'services/interfaces';

export default function Outline(
  { courseDetails }: {courseDetails: LearningObjectDetailsResponse},
) {
  const AccountStatusForm: IFormItem[] = [
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: courseDetails.outline[0].notesText,
      fieldId: 'outline',
      label: 'Outline',
      titleSize: 3,
    },
  ]
  return (
    <ViewFormLayout testId="outline">
      <ViewFormLayout.Title>
        Outline
      </ViewFormLayout.Title>
      <ViewFormLayout.Body>
        <FormView
          style={{ paddingLeft: 1, paddingRight: 1 }}
          fields={AccountStatusForm}
          onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
        />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  )
}
