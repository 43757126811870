import React, { useState } from 'react'
import FormView, { IFormItem } from 'components/FormView';
import ViewFormLayout from 'components/ViewFormLayout';
// eslint-disable-next-line import/extensions
import { LearningObjectDetailsResponse } from 'services/interfaces';
import { useGetCatalogTreeItemsQuery } from 'services/dataApi';
import { getOptionsCatalog } from 'modules/administrator/AdminScormCourses/ScormCoursesDetail/ScormAddNew';

function getCatalogIds(arr: number[]): Record<number, boolean> {
  const result: Record<number, boolean> = {};
  arr.forEach((value) => {
    result[value] = true;
  });
  return result;
}

export default function CourseInformation(
  { courseDetails }: {courseDetails: LearningObjectDetailsResponse},
) {
  const {
    data: dataCatalog,
  } = useGetCatalogTreeItemsQuery();
  const optionsCatalog = getOptionsCatalog(dataCatalog)
  const [, setTreeids] = useState<number[]>([])
  const AccountStatusForm: IFormItem[] = [
    {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: courseDetails.isCourseActive,
      fieldId: 'active',
      label: 'Active',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'select',
      options: [
        { key: '2', value: 'Online' },
        { key: '1', value: 'ITL' },
        { key: '-5', value: 'Certification' },
      ],
      fieldValue: courseDetails.learningObjectTypeId,
      fieldId: 'type',
      label: 'Type',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'textArea',
      options: undefined,
      fieldValue: courseDetails.learningObjectDescription,
      fieldId: 'description',
      label: 'Description',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'treeSelect',
      treeOptions: optionsCatalog,
      handleMultiSelectOptions: setTreeids,
      label: 'Catalog',
      defaultTreeItems: getCatalogIds(courseDetails.learningObjectTreeIds),
      titleSize: 3,
      fieldValue: '',
      fieldId: '',
    },
    {
      editable: true,
      mandatory: false,
      type: 'select',
      options: [
        { key: '1', value: 'Not a graded course' },
        { key: '2', value: 'Pass / Fail only' },
        { key: '3', value: 'Student must receive a passing score' },
      ],
      fieldValue: courseDetails.gradingTypeId,
      fieldId: 'grading',
      label: 'Grading',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'select',
      options: [
        { key: '1', value: 'AICC' },
        { key: '2', value: 'NoTrackingURLOnly' },
        { key: '3', value: 'TestOnly' },
        { key: '6', value: 'SingleSCORM' },
        { key: '8', value: 'MultiSCORM' },
        { key: '9', value: 'Pyxis' },
        { key: '10', value: 'Learning Tools Interoperability (LTI)' },
        { key: '11', value: 'Acknowledgement' },

      ],
      fieldValue: courseDetails.wbtTypeId,
      fieldId: 'WBT Type',
      label: 'Grading',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: courseDetails.wbturl,
      fieldId: 'wbturl',
      label: 'wbturl',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: courseDetails.passingScore,
      fieldId: 'passingScore',
      label: 'Passing Score',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: courseDetails.creditUnits,
      fieldId: 'creditUnits',
      label: 'Credit Units',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: courseDetails.creditHours,
      fieldId: 'creditHours',
      label: 'Credit Hours',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: courseDetails.duration,
      fieldId: 'duration',
      label: 'Duration',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: courseDetails.onlyForCertification,
      fieldId: 'onlyForCertification',
      label: 'Only for Certification',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: courseDetails.listPrice,
      fieldId: 'listPrice',
      label: 'List Price',
      titleSize: 3,
    }, {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: courseDetails.version,
      fieldId: 'version',
      label: 'Version',
      titleSize: 3,
    }, {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: courseDetails.allowROMS,
      fieldId: 'allowROMS',
      label: 'Allow ROMS',
      titleSize: 3,
    }, {
      editable: true,
      mandatory: false,
      type: 'textArea',
      options: undefined,
      fieldValue: courseDetails.dependents?.join(', ') || '',
      fieldId: 'dependents',
      label: 'Dependents',
      titleSize: 3,
    },
  ];

  const internalInformation: IFormItem[] = [
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: courseDetails?.creatorFullname,
      fieldId: 'creatorFullname',
      label: 'Created By',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: courseDetails?.createdDate,
      fieldId: 'createdDate',
      label: 'Created Date',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: courseDetails?.updaterFullname,
      fieldId: 'updaterFullname',
      label: 'Updated By',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: courseDetails.updatedDate,
      fieldId: 'updatedDate',
      label: 'Updated Date',
      titleSize: 3,
    },
  ];

  return (
    <div>
      <ViewFormLayout testId="course-information">
        <ViewFormLayout.Title>
          Course Information
        </ViewFormLayout.Title>
        <ViewFormLayout.Body>
          <FormView
            style={{ paddingLeft: 1, paddingRight: 1 }}
            fields={AccountStatusForm}
            onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
          />
        </ViewFormLayout.Body>
      </ViewFormLayout>
      <ViewFormLayout testId="internal-information">
        <ViewFormLayout.Title>
          Internal Information
        </ViewFormLayout.Title>
        <ViewFormLayout.Body>
          <FormView
            style={{ paddingLeft: 1, paddingRight: 1 }}
            fields={internalInformation}
            onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
          />
        </ViewFormLayout.Body>
      </ViewFormLayout>
    </div>
  )
}
