/* eslint-disable react/react-in-jsx-scope */
import { Folder } from '@mui/icons-material';
import { TreeNode } from 'primereact/treenode';
import { DivisionTreeItemsResponse } from 'services/interfaces';

export interface IFormResponse {
    id: string;
    value: any;
  }

export function transformItemsToOptionsDivisions(items: DivisionTreeItemsResponse[]): TreeNode[] {
  return items.map((item) => ({
    key: item.divisionId,
    label: item.divisionName,
    icon: <Folder color="primary" />,
    children: item.divisions ? transformItemsToOptionsDivisions(item.divisions) : [],
  }))
}

export function getOptionsDivisions(data: DivisionTreeItemsResponse | undefined) {
  if (data === undefined) return []
  return transformItemsToOptionsDivisions([data])
}

export function extractValues(
  informationArray: IFormResponse[],
): any {
  const initialUserDetails: { [key: string]: string | number } = {}

  informationArray.forEach((curr) => {
    initialUserDetails[curr?.id] = curr?.value
  });
  const finalUserDetails: any = {
    ...initialUserDetails,
  };

  return finalUserDetails;
}
