import React, { useState } from 'react'
import { ACTION_ITEM_TOOLTIP_TEXT, ACTIVE_STATUS } from 'utils/constants';
import {
  Box, Button, IconButton, Modal, Stack, Tooltip,
  Typography,
} from '@mui/material';
import { TRows, TColumns } from 'components/CustomTable/interface';
import { useNavigate } from 'react-router-dom';
import { UserGroup, UserGroupDeleteBody } from 'services/interfaces';
import CustomTable from 'components/CustomTable';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ViewFormLayout from 'components/ViewFormLayout';
import { useDeleteUserGroupMutation } from 'services/dataApi';
import { enqueueSnackbar } from 'notistack';
import { handleErrorMessage } from 'utils/helpers';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import LoadingBox from 'components/LoadingBox';

interface ModalProps {
  open: boolean, onClose: Function, onConfirm: Function
}
function ConfirmDeleteModal({ open, onClose, onConfirm }: ModalProps) {
  return (
    <Modal open={open} onClose={() => onClose && onClose()} aria-labelledby="confirm-delete-modal">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 2,
          borderRadius: 1,
        }}
      >
        <Typography id="confirm-delete-modal" variant="h6" component="h2" gutterBottom>
          Do you want to delete this group?
        </Typography>
        <Stack display="flex" flexDirection="row" gap={1} justifyContent="flex-end" mt={2}>
          <Button variant="outlined" onClick={() => onClose && onClose()}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => onConfirm && onConfirm()}>
            Confirm
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}

interface UserGroupsProps {
  userId: number | string,
  userGroups: UserGroup[],
  reloadData: Function,
}
export default function UserGroups({
  userId, userGroups, reloadData,
}: UserGroupsProps) {
  const navigate = useNavigate();

  const [deleteUserGroup, { isLoading: isLoadingDelete }] = useDeleteUserGroupMutation();
  const [openGroupId, setOpenGroupId] = useState<number | null>(null);

  const handleOpen = (userGroupId: number) => setOpenGroupId(userGroupId);
  const handleClose = () => setOpenGroupId(null);

  const handleDeleteRegister = async (userGroupId: number) => {
    const errorList: string[] = [];

    if (!userGroupId) {
      errorList.push('The field userGroupId is required!')
    }

    const body: UserGroupDeleteBody = {
      userId,
      userGroupId,
    }
    try {
      if (errorList.length === 0) {
        await deleteUserGroup(body).unwrap();
        enqueueSnackbar('Group deleted Successfully', { variant: 'success' });
        if (reloadData) reloadData()
      } else {
        errorList?.forEach((error) => enqueueSnackbar(error, { variant: 'error' }))
      }
    } catch (error: any) {
      const message = handleErrorMessage(error as FetchBaseQueryError)
      enqueueSnackbar(message, { variant: 'error' });
    }
  }

  const handleConfirm = () => {
    handleDeleteRegister(openGroupId as number)
    handleClose();
  };

  function getActionButtons(group: UserGroup, _handleOpen: Function) {
    return (
      <Box display="flex">
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.EDIT_GROUP}>
          <IconButton
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            onClick={() => navigate(`/admin/user/profile/edit-group/${userId}`, {
              state: group,
            })}
          >
            <EditIcon sx={{ color: (theme) => theme.palette.primary.main }} />
          </IconButton>
        </Tooltip>
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.DELETE_GROUP}>
          <IconButton
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            onClick={() => _handleOpen(group?.userGroupId)}
          >
            <DeleteForeverOutlinedIcon sx={{ color: (theme) => theme.palette.primary.main }} />
          </IconButton>
        </Tooltip>
      </Box>
    )
  }
  const hasPrimaryDivision = userGroups.some((item) => item.isPrimaryJobPositionDivision);

  const columns: TColumns[] = [
    {
      name: 'Group Name',
      column: 'groupName',
      width: 250,
      sortable: true,
      hidden: false,
    },
    {
      name: 'Primary Division',
      column: 'isPrimaryDivision',
      width: 150,
      sortable: false,
      hidden: false,
    },
    {
      name: 'Division',
      column: 'departmentName',
      width: 340,
      sortable: false,
      hidden: false,
    },
    {
      name: 'Primary Job Position/ Division',
      column: 'isPrimaryJobPositionDivision',
      width: 120,
      sortable: true,
      hidden: false,
    },
    {
      name: 'Primary Job Position',
      column: 'jobPosition',
      width: 120,
      sortable: true,
      hidden: false,
    },
    {
      name: 'Position',
      column: 'jobPositionDescription',
      width: 180,
      sortable: false,
      hidden: false,
    },
    {
      name: 'Active',
      column: 'userGroupActive',
      width: 80,
      sortable: false,
      hidden: false,
    },
    {
      name: 'Actions',
      column: 'actions',
      align: 'right',
      width: 80,
      sortable: false,
      hidden: false,
    },
  ];

  const dataSet: TRows[] = (userGroups || []).map((userGroup: UserGroup) => ({
    ...userGroup,
    id: userGroup?.userGroupId,
    isCollapsable: false,
    details: [],
    // eslint-disable-next-line max-len
    isPrimaryJobPositionDivision: userGroup?.isPrimaryJobPositionDivision ? ACTIVE_STATUS.YES : ACTIVE_STATUS.NO,
    userGroupActive: userGroup?.userGroupActive ? ACTIVE_STATUS.YES : ACTIVE_STATUS.NO,
    actions: getActionButtons({ ...userGroup, hasPrimaryDivision }, handleOpen),
  }))

  return (
    <ViewFormLayout testId="groups-belong-to">
      <ViewFormLayout.Title>
        Groups User belong to
      </ViewFormLayout.Title>
      <ViewFormLayout.Body>
        {isLoadingDelete ? <LoadingBox /> : (
          <CustomTable
            rows={dataSet}
            columns={columns}
            collapseAllRow={false}
            defaultSort="userGroupActive"
          />
        )}
        <ConfirmDeleteModal
          open={Boolean(openGroupId)}
          onClose={handleClose}
          onConfirm={handleConfirm}
        />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  )
}
