import React from 'react';
import { Box } from '@mui/material';
import { GetJobPositionRequest, JobPositionResponse } from 'services/interfaces';
import { TColumns, TRows } from 'components/RemoteCustomTable/interface';
import { useGetJobPositionsByFilterMutation } from 'services/dataApi';
import LoadingBox from 'components/LoadingBox';
import RemoteCustomTable from 'components/RemoteCustomTable';
import useRemoteTable from 'components/RemoteCustomTable/useRemoteTable';

interface TableProps {
  filters: GetJobPositionRequest;
  isSingleSelection: boolean;
  onCallback: Function;
}
export default function JobPositionsTable({ filters, onCallback, isSingleSelection }: TableProps) {
  const [getJobPositionByFilter] = useGetJobPositionsByFilterMutation();

  const {
    dataSet,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    sort,
    setSort,
    sortDirection,
    setSortDirection,
    isLoading,
  } = useRemoteTable<JobPositionResponse, GetJobPositionRequest>({
    fetchData: (request) => getJobPositionByFilter(request).unwrap(),
    filters,
    sortDefault: 'jobPositionId',
  });

  function createData(items: JobPositionResponse[]): TRows[] {
    return items?.map((item: JobPositionResponse) => ({
      ...item,
      details: [],
      id: Number(item.jobPositionId),
      isCollapsable: false,
    }));
  }

  const columns: TColumns[] = [
    {
      name: 'ID',
      column: 'jobPositionId',
      width: 100,
      sortable: true,
    },
    {
      name: 'Job Position',
      column: 'jobPosition',
      width: 200,
      sortable: true,
    },
    {
      name: 'Description',
      column: 'jobPositionDescription',
      width: 250,
      sortable: true,
    },
    {
      name: 'Job Code ID',
      column: 'jobCodeId',
      width: 150,
      sortable: true,
    },
    {
      name: 'Job Class ID',
      column: 'jobClassId',
      width: 150,
      sortable: true,
    },
  ];

  if (isLoading) {
    return <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}><LoadingBox /></Box>
  }
  return (
    <RemoteCustomTable
      rows={createData(dataSet?.data || [])}
      columns={columns}
      totalRecords={dataSet?.totalRows || 0}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      sortDirection={sortDirection}
      setSortDirection={setSortDirection}
      sort={sort}
      setSort={setSort}
      isSingleSelection={isSingleSelection}
      showSelectCheckbox
      onHandleSelectCheckbox={(selected: number[]) => onCallback(selected)}
    />

  )
}
