import React from 'react';
import Layout from 'components/Layout';
import { useLocation, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { UserGroup } from 'services/interfaces';
import UserEditGroup from 'modules/administrator/AdminUserProfile/UserEditGroup';

function AdminUserEditGroupPage() {
  const location = useLocation();
  const { userId } = useParams();
  const groupDetail: UserGroup = location?.state

  if (!groupDetail?.groupId || !userId) {
    return (
      <Layout>
        <Typography>No user/group selected!</Typography>
      </Layout>
    )
  }

  return (
    <Layout>
      <UserEditGroup userId={Number(userId)} userGroup={groupDetail} />
    </Layout>
  );
}

export default AdminUserEditGroupPage;
