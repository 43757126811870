/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import {
  Box, Button, Grid, InputAdornment, Stack, TextField, Tooltip,
  Typography,
} from '@mui/material';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import {
  DivisionTreeItemsResponse, EmploymentType, GetGroupResponse, GetGroupsRequest, GroupResponse, JobPositionResponse, UserGroup,
  UserGroupBody,
} from 'services/interfaces';
import { Folder } from '@mui/icons-material';
import { TreeNode } from 'primereact/treenode';
import { useNavigate } from 'react-router-dom';
import {
  useEditUserGroupMutation, useGetDivisionsTreeItemsQuery, useGetEmploymentTypeQuery, useGetGroupsByFilterMutation,
} from 'services/dataApi';
import FormView, { customSelectOption, IFormItem } from 'components/FormView';
import LoadingIcon from 'components/LoadingIcon';
import ViewFormLayout from 'components/ViewFormLayout';
import JobPositionsCatalog from 'modules/SearchableCatalogs/JobPositionsCatalog';
import SearchIcon from '@mui/icons-material/Search';
import { IFormValue } from 'utils/interfaces';
import { enqueueSnackbar } from 'notistack';
import { handleErrorMessage } from 'utils/helpers';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { extractValues } from './helpers';

function transformItemsToOptionsDivisions(items: DivisionTreeItemsResponse[]): TreeNode[] {
  return items.map((item) => ({
    key: item.divisionId,
    label: item.divisionName,
    icon: <Folder color="primary" />,
    children: item.divisions ? transformItemsToOptionsDivisions(item.divisions) : [],
  }))
}

function getOptionsDivisions(data: DivisionTreeItemsResponse | undefined) {
  if (data === undefined) return []
  return transformItemsToOptionsDivisions([data])
}

  interface UserEditGroupProps {
    userGroup: UserGroup;
    userId: number;
  }

export default function UserEditGroup({ userId, userGroup }: UserEditGroupProps) {
  const navigate = useNavigate();
  const { data: dataDivisions, isLoading: isLoadingDivision } = useGetDivisionsTreeItemsQuery();
  const { data: employmentType, isLoading: isLoadingEmployment } = useGetEmploymentTypeQuery();
  const [getGroupsByFilter, { isLoading: isLoadingGroups }] = useGetGroupsByFilterMutation();
  const [editUserGroup, { isLoading: isLoadingEditGroup }] = useEditUserGroupMutation();
  const optionsDivisions = getOptionsDivisions(dataDivisions)
  const [treeIdsDivision, setTreeIdsDivision] = useState<number[]>([userGroup?.divisionId])
  const [groups, setGroups] = useState<customSelectOption[]>([])
  const [jobPosition, setJobPosition] = useState<JobPositionResponse[]>([{
    jobPositionId: Number(userGroup?.jobPositionId),
    jobPosition: userGroup?.jobPosition,
    jobPositionDescription: userGroup?.jobPositionDescription,
    jobCodeId: Number(userGroup?.jobCode),
    jobClassId: Number(userGroup?.jobClass),
  }]);
  const [open, setOpen] = useState(false);
  const [formValue, setFormValue] = useState<IFormValue[]>([
    { id: 'groupId', value: userGroup?.groupId },
    { id: 'userGroupId', value: userGroup?.userGroupId },
  ]);

  const isLoadingState = isLoadingDivision || isLoadingGroups || isLoadingEmployment || isLoadingEditGroup;

  const handleSubmit = async () => {
    const values = extractValues(formValue);
    // validate mandatories
    const errorList: string[] = [];

    if (!values?.groupId) {
      errorList.push('The field Group is required!')
    }
    if (!treeIdsDivision.length) {
      errorList.push('The field Division is required!')
    }

    const body: UserGroupBody = {
      userId,
      userGroupId: userGroup?.userGroupId,
      groupId: values?.groupId,
      divisionId: treeIdsDivision[0],
      active: values?.active,
      isPrimaryJobPositionDivision: values?.isPrimaryJobPositionDivision,
      jobPositionId: jobPosition[0].jobPositionId,
      employmentTypeId: values?.employmentTypeId || null,
      dateHired: values?.dateHired || null,
    }

    try {
      if (errorList.length === 0) {
        await editUserGroup(body).unwrap();
        enqueueSnackbar('Group edited Successfully', { variant: 'success' });
        navigate(-1)
      } else {
        errorList?.forEach((error) => enqueueSnackbar(error, { variant: 'error' }))
      }
    } catch (error: any) {
      const message = handleErrorMessage(error as FetchBaseQueryError)
      enqueueSnackbar(message, { variant: 'error' });
    }
  }

  const handleFormResponse = (obj: { id: string; value: any }) => {
    setFormValue((_values) => {
      const existingIndex = _values?.findIndex((item) => item.id === obj.id);
      return existingIndex !== -1
        ? _values?.map((item, index) => (index === existingIndex ? obj : item))
        : [..._values, obj];
    })
  };

  const fetchGroupData = async () => {
    try {
      const body: GetGroupsRequest = {
        groupId: null,
        name: null,
        PageNumber: 1,
        PageSize: 999,
        SortingBy: 'groupId',
        Ascending: true,
      }
      const response: GetGroupResponse = await getGroupsByFilter(body).unwrap();
      const group = response?.data?.map(({ groupId, description, name }: GroupResponse) => ({
        value: `${name} - ${description}`,
        key: groupId,
      }))
      setGroups(group)
    } catch (error) {
      const message = handleErrorMessage(error as FetchBaseQueryError)
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const NewUserGroupFields: IFormItem[] = [
    {
      editable: false,
      mandatory: false,
      type: 'select',
      options: groups,
      fieldValue: userGroup?.groupId,
      fieldId: 'groupId',
      label: 'Group',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: userGroup?.userGroupActive,
      fieldId: 'active',
      label: 'Active',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'select',
      options: employmentType?.map((emT: EmploymentType) => ({ key: emT.id, value: emT.name })),
      fieldValue: userGroup?.employmentTypeId,
      fieldId: 'employmentTypeId',
      label: 'Employment Type',
      titleSize: 3,
    },
    {
      editable: !userGroup?.hasPrimaryDivision || userGroup?.isPrimaryJobPositionDivision,
      mandatory: false,
      type: 'checkbox',
      options: undefined,
      fieldValue: userGroup?.isPrimaryJobPositionDivision,
      fieldId: 'isPrimaryJobPositionDivision',
      label: 'Primary Position/Division',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'date',
      options: undefined,
      fieldValue: userGroup?.dateHired,
      fieldId: 'dateHired',
      label: 'Hire Date',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: true,
      type: 'treeSelect',
      treeOptions: optionsDivisions,
      handleMultiSelectOptions: setTreeIdsDivision,
      label: 'Divisions',
      titleSize: 3,
      fieldValue: userGroup?.divisionId,
      defaultTreeItems: { [userGroup?.divisionId]: true },
      fieldId: 'divisionId',
    },
  ];

  useEffect(() => {
    fetchGroupData();
  }, []);

  if (isLoadingState) {
    return (
      <Stack p={2}>
        <LoadingIcon />
      </Stack>
    )
  }

  return (
    <>
      <ViewFormLayout pl={0} pr={0} testId="users-list">
        <ViewFormLayout.ActionBar>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.BACK}>
            <Button variant="text" onClick={() => navigate(-1)}>
              {BUTTON_TEXT.BACK}
            </Button>
          </Tooltip>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.SAVE}>
            <Button variant="contained" onClick={handleSubmit}>
              {BUTTON_TEXT.SAVE}
            </Button>
          </Tooltip>
        </ViewFormLayout.ActionBar>
        <ViewFormLayout.Body>
          <Box
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              pl: 2,
              pb: 2,
            }}
          >
            <FormView
              title="Edit User Group"
              style={{ paddingLeft: 1, paddingRight: 1 }}
              fields={NewUserGroupFields}
              onChangeValues={(obj: { id: string; value: any }) => handleFormResponse(obj)}
            />
            <Grid container>
              <Grid
                item
                xs={3}
                bgcolor={(theme) => theme.palette.grey[100]}
                className="center-vertical"
                sx={{
                  justifyContent: 'right', borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}`, pl: 1, pr: 1,
                }}
              >
                <Typography>Job Position</Typography>
              </Grid>
              <Grid item xs={9} p={1} className="center-vertical">
                <TextField
                  fullWidth
                  id="input-with-icon-textfield"
                  value={jobPosition.map((job) => job?.jobPositionDescription).join('; ')}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                />
              </Grid>
            </Grid>
          </Box>
        </ViewFormLayout.Body>
      </ViewFormLayout>
      <JobPositionsCatalog
        open={open}
        onClose={() => setOpen(false)}
        isSingleSelection
        onCallback={(selected: any) => {
          setJobPosition(selected)
          setOpen(false)
        }}
      />
    </>

  );
}
