import React from 'react'
import FormView, { IFormItem } from 'components/FormView';
import ViewFormLayout from 'components/ViewFormLayout';
// eslint-disable-next-line import/extensions
import { LearningObjectDetailsResponse } from 'services/interfaces';

export default function Advance(
  { courseDetails }: {courseDetails: LearningObjectDetailsResponse},
) {
  const AccountStatusForm: IFormItem[] = [
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: courseDetails.tests ? courseDetails.tests[0].testName : '',
      fieldId: 'test',
      label: 'Test',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: courseDetails.pretests ? courseDetails.pretests[0].testDetails : '',
      fieldId: 'pre-test',
      label: 'PreTest',
      titleSize: 3,
    },
    {
      editable: true,
      mandatory: false,
      type: 'input',
      options: undefined,
      fieldValue: courseDetails.ownership ? courseDetails.ownership[0].ownerFullname : '',
      fieldId: 'ownerFullname',
      label: 'Ownership',
      titleSize: 3,
    },
  ]

  return (
    <ViewFormLayout testId="advance">
      <ViewFormLayout.Title>
        Advanced
      </ViewFormLayout.Title>
      <ViewFormLayout.Body>
        <FormView
          style={{ paddingLeft: 1, paddingRight: 1 }}
          fields={AccountStatusForm}
          onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
        />
      </ViewFormLayout.Body>
    </ViewFormLayout>

  )
}
